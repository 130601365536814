import React from "react";
import { Link, graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { rhythm } from "../utils/typography";

class Portfolio extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;
    const portfolios = data.allContentfulPortfolio.edges;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Portfolio"
          keywords={[`painting`, `portfolio`, `michigan`, `historical`]}
        />
        <div id="portfolio">
          {portfolios.map(({ node }) => {
            const { title, images } = node;
            return (
              <div className="portfolio-container" key={title}>
                <Link to={title}>
                  <img src={images[0].fluid.src} alt="Featured portfolio" />
                </Link>
                <h3
                  style={{
                    marginBottom: rhythm(1 / 4)
                  }}
                >
                  <Link style={{ boxShadow: `none` }} to={title}>
                    {title}
                  </Link>
                </h3>
                <p className="image-count">{`${images.length} images`}</p>
              </div>
            );
          })}
        </div>
      </Layout>
    );
  }
}

export default Portfolio;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulPortfolio {
      edges {
        node {
          title
          images {
            fluid {
              src
              aspectRatio
            }
          }
        }
      }
    }
  }
`;
